<template>
  <div id="views\tramites\components\DatosAdquisicion">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-text
          style="background: #eee; overflow-y: scroll; max-height: 72vh"
        >
          <v-row class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <base-material-card color="primary" class="px-5 py-3">
                <template v-slot:heading>
                  <div class="text-h5 font-weight-light my-2">
                    Datos de la Adquisición
                  </div>
                </template>
                <v-card-text>
                  <v-row class="mx-0 justify-center">
                    <v-col cols="12" md="2" sm="12" class="centered-input">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.fecha_adquisicion"
                            label="Fecha de Adquisición"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date = parseDate(form.fecha_adquisicion)"
                            v-on="on"
                            v-on:focus="dateValido"
                            :rules="[
                              $rulesRequerido,
                              $rulesFechaValida,
                              $rulesFechaMenorAHoy,
                            ]"
                            v-mask="'##/##/####'"
                            placeholder="dd/mm/yyyy"
                            maxLength="10"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          class="ma-0"
                          no-title
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                      <v-autocomplete
                        :items="tipos_adquisiciones"
                        item-value="id"
                        item-text="nombre"
                        label="Tipo de Adquisición"
                        v-model="form.tipo_adquisicion_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                      <v-autocomplete
                        :items="modos_adquisiciones"
                        item-value="id"
                        item-text="nombre"
                        label="Modo de Adquisición"
                        v-model="form.modo_adquisicion_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                      <v-autocomplete
                        :items="monedas"
                        item-value="id"
                        item-text="nombre"
                        label="Moneda"
                        v-model="form.moneda_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                      <vuetify-money
                        v-model="form.importe"
                        label="Importe"
                        v-bind:options="options"
                        clearable
                        :rules="[$rulesFloatRequerido]"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 justify-center">
                    <v-col cols="12" md="4" sm="12" v-on="">
                      <v-autocomplete
                        :items="tipos_facturas"
                        item-value="id"
                        item-text="nombre"
                        label="Tipo de Factura"
                        v-model="form.tipo_factura_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                        dense
                        return-object
                        style="top: 20px"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <v-text-field
                        v-model="form.factura"
                        :readonly="readonly"
                        label="Número de Factura"
                        :rules="[
                          $rulesRequerido,
                          $rulesNumericos,
                          $facturaLength,
                        ]"
                        maxlength="12"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" sm="12">
                      <v-text-field
                        v-model="form.punto_venta"
                        :readonly="readonly"
                        label="Codigo del punto de venta"
                        :rules="[
                          $rulesRequerido,
                          $rulesNumericos,
                          $puntoVentaLength,
                        ]"
                        maxlength="4"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 justify-center">
                    <v-col cols="12" md="10" sm="12">
                      <v-textarea
                        v-model="form.razon_social_probatorio"
                        :readonly="readonly"
                        label="Razón social Elemento probatorio"
                        auto-grow
                        outlined
                        rows="4"
                        row-height="15"
                        :rules="[$rulesRequerido]"
                        dense
                        clearable
                        clear-icon="mdi-close-circle"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="2" sm="12">
                      <v-autocomplete
                        :items="itemsSN"
                        item-value="id"
                        item-text="nombre"
                        label="Segundo elemento probatorio"
                        v-model="form.probatorio"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                        return-object
                        style="top: 20px"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right" cols="12" md="12" sm="12">
              <v-btn color="red" @click="$exit(idTramite)">Salir</v-btn>
              <v-btn color="primary-2" @click="back()">Atras</v-btn>
              <v-btn color="primary" @click="next()">Siguiente</v-btn>
            </v-col>
          </v-row>
          <br />
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.right-input >>> input {
  text-align: right;
}
.chip-indicadores {
  color: "blue-grey--text darken-4";
  font-size: 14px;
  /*font-family: 'Material Icons';*/
  /*font-weight: bold;*/
  font-style: normal;
}

.primary-change {
  color: #fff !important;
  /*background-color: #a7a8a8 !important;*/
  background-color: #105cfa !important;
  font-size: 20px;
  font-weight: bold;
  height: 70px !important;
  display: flex;
  align-items: center;
}
.col-f12 {
  text-align: center;
  font-size: 2rem;
  /*border-radius: 28px;*/
  font-style: #a0a0a0 !important;
  color: #a0a0a0 !important;
  font-weight: 500 !important;
  border-bottom: solid;
  border-color: #011f5b;
  /*border-radius: 28px;*/
  /*background: #eee;*/
  padding: 8px 0px 23px 17px;
  height: 55px;
  margin-top: 7px;
  margin-left: 7px !important;
  margin-right: 15px;
}
.centered-input >>> input {
  text-align: center;
  font-size: 1.25rem;
  color: #105cfa !important;
  border-bottom: solid;
  border-color: #011f5b;
}
</style>
<script>
export default {
  name: "DatosAdquisicion",
  components: {},
  props: {
    tramite: { Object, default: null },
    tipoF01: { String, default: null },
    idTramite: { Number, default: null },
    readonly: { type: Boolean, default: false },
    datosAdquisicion: { Object, default: null },
  },
  data: (vm) => ({
    mask: "##/##/####",

    options: {
      locale: "es-AR",
      prefix: "$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    tipos_adquisiciones: [
      { id: 1, nombre: "Común" },
      { id: 2, nombre: "Ley 19279" },
      { id: 3, nombre: "Importador Directo" },
      { id: 4, nombre: "Bomberos" },
    ],
    modos_adquisiciones: [
      { id: 1, nombre: "Contado" },
      { id: 2, nombre: "Otros Préstamos" },
    ],
    monedas: [
      { id: 1, nombre: "Pesos" },
      { id: 2, nombre: "Dólares" },
    ],
    tipos_facturas: [
      { id: 1, nombre: "Factura A" },
      { id: 2, nombre: "Factura B" },
      { id: 3, nombre: "Factura C" },
      { id: 4, nombre: "Factura M" },
      { id: 5, nombre: "Factura de Exportación" },
      { id: 6, nombre: "Certificado" },
    ],
    snackbar: false,
    color: "success",
    timeout: 4000,
    text: "Se ha registrado una nueva solicitud",

    itemsSN: [
      { id: "S", nombre: "SI" },
      { id: "N", nombre: "NO" },
    ],
    tipo_vehiculos: [],
    valid: true,
    autoGrow: true,
    rows: 5,
    nowDate: new Date().toISOString().slice(0, 10),
    menu: false,
    form: {
      fecha_adquisicion: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
  }),

  methods: {
    back() {
      this.$emit("send-message", "3");
    },
    next() {
      //if (this.$refs.form.validate() && this.primeravez) {
      if (this.$refs.form.validate()) {
        this.$emit("send-message", "5", "4", this.form);
      } else {
        this.snackbar = true;
        this.text = "Verifique los datos ingresados y/o campos obligatorios";
        this.color = "error";
      }
    },
    setDatosPrueba() {
      this.form.fecha_adquisicion = "12/09/1983";
      this.form.tipo_adquisicion_id = { id: 1, nombre: "Común" };
      this.form.modo_adquisicion_id = { id: 1, nombre: "Contado" };
      this.form.moneda_id = { id: 1, name: "Pesos" };
      this.form.importe = "100";
      this.form.tipo_factura_id = { id: 1, nombre: "Factura A" };
      this.form.factura = "12000332212";
      this.form.punto_venta = 12345;
      this.form.razon_social_probatorio = "DETALLE DE LA RAZON SOCIAL";
      this.form.probatorio = { id: "S", nombre: "SI" };
    },
    formatDate(date) {
      try {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      } catch (error) {
        console.log("formatDate Error");
      }
    },
    parseDate(date) {
      try {
        if (!date) return null;
        if (date.length !== 10) {
          this.form.fecha_adquisicion = this.formatDate(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          );
          return;
        }
        const [month, day, year] = date.split("/");
        return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      } catch (error) {
        console.log("parseDate Error");
      }
    },
    dateValido() {
      if (!this.form.fecha_adquisicion) {
        this.form.fecha_adquisicion = this.formatDate(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        );
      }
    },
    datePreSet() {
      if (this.form.fecha_adquisicion) {
        console.log(this.form.fecha_adquisicion);
        const [month, day, year] = this.form.fecha_adquisicion.split("/");
        this.date = `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      }
    },
  },
  computed: {},
  created() {
    //this.setDatosPrueba();

    this.valid = true;
  },

  watch: {
    date(val) {
      this.form.fecha_adquisicion = this.formatDate(this.date);
    },
  },
  mounted() {
    if (this.datosAdquisicion) {
      if (Object.keys(this.datosAdquisicion).length) {
        console.log(this.datosAdquisicion);
        this.form = this.datosAdquisicion;
      }
      console.log("Componente Datos Tramite creado");
    }
    this.datePreSet();
  },
};
</script>
